import Rails from "@rails/ujs";

const rootUrl = location.protocol + "//" + location.host + location.pathname;
const mediaTypes = document.getElementById("media-types");
const rssInput = document.getElementById("rss-url");
const storefronts = document.getElementById("storefronts");
const formats = document.getElementById("formats");
const rateLimits = document.getElementById("rate-limits");
const charts = document.getElementById("charts");
const types = document.getElementById("types");
const feedResults = document.getElementById("feed-results");
const copyButton = document.getElementById("copy-button");

let t = null;

// From https://stackoverflow.com/a/30810322/103778
function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

copyButton.addEventListener("click", (e) => {
  e.preventDefault();
  copyTextToClipboard(rssInput.value);
  e.target.innerHTML = "&#10697; Copied!";
  setTimeout(() => {
    e.target.innerHTML = "&#10697; Copy";
  }, 2000);
});

const buildRssUrl = () => {
  // Show loading spinner
  const feedResults = document.getElementById("feed-results");
  feedResults.innerHTML = '<div class="loading" />';

  const path = `${types.value}.${formats.value}`;
  rssInput.value = `${rootUrl}api/v2/${storefronts.value}/${mediaTypes.value}/${charts.value}/${rateLimits.value}/${path}`;
  if (!t) {
    t = setTimeout(() => {
      rssInput.dispatchEvent(new Event("change", { bubbles: true }));
      t = null;
    }, 300);
  }
};

rssInput.addEventListener("change", () => {
  if (
    storefronts.value &&
    mediaTypes.value &&
    charts.value &&
    types.value &&
    rateLimits.value
  ) {
    const previewUrl = `${rootUrl}preview/${storefronts.value}/${mediaTypes.value}/${charts.value}/${types.value}?count=${rateLimits.value}`;
    Rails.ajax({
      url: previewUrl,
      type: "get",
      dataType: "script",
    });
  }
});

mediaTypes.addEventListener("change", (event) => {
  let input = event.target;
  if (!input.value) return;
  // clear out types and feeds
  types.innerHTML = "";
  charts.innerHTML = "";

  Rails.ajax({
    url: `/apple/${input.value}/storefronts`,
    type: "get",
    dataType: "script",
  });
});

storefronts.addEventListener("change", (event) => {
  let input = event.target;
  if (!input.value) return;
  // clear out types and feeds
  types.innerHTML = "";
  charts.innerHTML = "";

  Rails.ajax({
    url: `/apple/${mediaTypes.value}/${input.value}/types`,
    type: "get",
    dataType: "script",
  });
});

types.addEventListener("change", (event) => {
  let input = event.target;
  if (!input.value) return;
  Rails.ajax({
    url: `/apple/${mediaTypes.value}/${storefronts.value}/${input.value}/feeds`,
    type: "get",
    dataType: "script",
  });
});

storefronts.addEventListener("change", () => {
  buildRssUrl();
});
formats.addEventListener("change", () => {
  buildRssUrl();
});
types.addEventListener("change", () => {
  buildRssUrl();
});
charts.addEventListener("change", () => {
  buildRssUrl();
});
rateLimits.addEventListener("change", () => {
  buildRssUrl();
});

feedResults.addEventListener("click", (event) => {
  const input = event.target;
  if (!input.classList.contains("view-items")) {
    return;
  }
  if (input.classList.contains("view-more")) {
    input.classList.add("view-less");
    input.classList.remove("view-more");

    input.innerText = "View Less";

    document.querySelector(".view-less-label").classList.remove("hidden");
    document.querySelector(".view-more-label").classList.add("hidden");

    const items = document.querySelectorAll(".default-hide");
    items.forEach((item) => {
      item.classList.remove("hidden");
    });
  } else {
    input.classList.add("view-more");
    input.classList.remove("view-less");

    input.innerText = "View More";

    document.querySelector(".view-less-label").classList.add("hidden");
    document.querySelector(".view-more-label").classList.remove("hidden");

    const items = document.querySelectorAll(".default-hide");
    items.forEach((item) => {
      item.classList.add("hidden");
    });
  }
});

// Load storefronts and friends for first option selected
Rails.ajax({
  url: `/apple/${mediaTypes.value}/storefronts`,
  type: "get",
  dataType: "script",
});

buildRssUrl();
